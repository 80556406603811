import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import lodash from 'lodash';
import clsx from 'clsx';

import SearchIcon from '@mui/icons-material/Search';
import Typography from '@mui/material/Typography';

import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper";
import { GlobalHotKeys } from 'react-hotkeys';

import FullScreen from '../../Components/FullScreen';
import Interstitial from '../../Components/Interstitial';
import LowerThirdBanner from '../../Components/LowerThirdBanner';

import Logo from '../../Components/Logo';

import styles from './Ceremony.module.scss';
class Ceremony extends Component {

  constructor(props) {
    super(props);

    this.state = {
      bannerId: undefined,
      showBanner: false,
    };

    this.swiperRef = React.createRef();
    this.CeremonyRef = React.createRef();

    this.handleNextSlide = this.handleNextSlide.bind(this);
    this.handlePrevSlide = this.handlePrevSlide.bind(this);
    this.clearBanner = this.clearBanner.bind(this);

    this.swiperKeyMap = {
      Next: 'ArrowRight',
      Prev: 'ArrowLeft'
    };

    this.swiperHandlers = {
      Next: this.handleNextSlide,
      Prev: this.handlePrevSlide
    };
  }

  handleNextSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slideNext(300);
  }
  handlePrevSlide() {
    this.swiperRef.current.swiper.autoplay.stop();
    this.swiperRef.current.swiper.slidePrev(300);
  }

  renderInterstitial() {
    return (
      <Interstitial message="We'll Be Right Back" />
    );
  }

  renderFinalScoreBumper(title, maker, clubName, pretext, score, isExtra = false, scoreLabel = 'Points') {
    return (
      <div className={styles.bumper}>
        <div className={styles.info}>
          <Typography variant="h3">{`${title} by ${maker}`}</Typography>
          <Typography variant="h4" className={styles.clubName}>{`${clubName}`}</Typography>
        </div>
        <div className={styles.scoreBox}>
          {pretext && <div className={styles.pretext}>
            <Typography variant="h5">
              {pretext}
            </Typography>
          </div>}
          {(score !== null && this.props.hideScores === false) && 
            <Fragment>
              <div className={styles.scoreNumber}>{Math.ceil(score)}</div>
              <div className={styles.scoreText}>{scoreLabel}</div>
            </Fragment>
          }
          {(score !== null && isExtra && this.props.showScoresInExtras) &&
            <Fragment>
              <div className={styles.year}>{Math.ceil(score)}</div>
            </Fragment>
          }
        </div>
      </div>
    );
  }

  renderStandings(title) {
    // roll end credits here like in the movies with credits as each category
    //  with the names of the winners and their scores the key is the username and the 
    //  data is name / score for each cateogry
    const standings = this.props.standings;
    
    // // first lets get the categories
    // const categories = Object.keys(Object.values(standings).reduce((acc, current) => {
    //   acc[current.category] = true;
    //   return acc;
    // }, {}));

    return (
      <div className={styles.standingsContainer}>
      <Typography variant="h1">{title}</Typography>
          <div className={styles.standings}>
              <Typography class="column-title" variant="h2"></Typography>
              <Typography class="column-title" variant="h2">Color</Typography>
              <Typography class="column-title" variant="h2">Monochrome</Typography>
        {Object.values(standings).sort((a,b) => {
          return (b.color + b.monochrome) - (a.color + a.monochrome);
        }).map((standing, index) => {
          const renderRow = (index) => {
            let styleObj = {
            };
            if (index === 0) {
              styleObj = {
                backgroundColor: "gold",
                color: "black",
                fontWeight: "900",
              };
            }
            return (
              <Fragment key={index}>
                <Typography variant="h2" style={styleObj}>{standing.name}</Typography>
                <Typography variant="h2" style={styleObj}>{standing.color}</Typography>
                <Typography variant="h2" style={styleObj}>{standing.monochrome}</Typography>
              </Fragment>
            );
        };

          return (
            <Fragment key={index}>
              {renderRow(index)}
            </Fragment>
          );
        })}
        </div>
      </div>);

  }

  renderImage(id, type, slide, url) {
    if (type === "title") {
      return (
        <div className={styles.titleContainer}>
          <div><Typography variant="h1" className={styles.title}>{slide.title}</Typography></div>
          <div><Typography variant="h2" className={styles.subtitle}>{slide.subtitle}</Typography></div>
          <Logo variant="dark" className={styles.logo}/>
        </div>
      );
    } else if (type === "standings") {
      return (
        this.renderStandings(slide.title)
      );
    } else if (type === "signoff") {
      return (
        <div className={styles.signOffContainer}>
          <Logo variant="dark" className={styles.logoLarge}/>
        </div>
      );
    }
    return (
      <img src={url} alt={id} className={styles.image} />
    );
  }

  renderWinnersSwiper() {
    const data = this.props.winners.map(({ id, url, categoryDisplayName, clubName, score, award, slide, isExtra }) => {
      if (!id) {
        return {
          type: slide.type,
          id: slide.title,
          slide,
        };
      } 
      const info = this.props.getImageInfo(id);
        if (!info) {
          return {
            type: "title",
            slide,
            id: slide.title,
            url,
          };
        }
        let banner;
        if (this.props.eventType === 'placed-competition-with-mentions') {
          banner = this.renderFinalScoreBumper(info.title, info.maker, clubName, award, score, isExtra);
        } else if (this.props.eventType === 'placed-competition') {
          if (info.totalVotes) {
            banner = this.renderFinalScoreBumper(info.title, info.maker, categoryDisplayName, award, score, false, 'votes');
          } else if (this.props.bestInShow === id) {
            banner = this.renderFinalScoreBumper(info.title, info.maker, "Best in Show", award, score);
          } else {
            banner = this.renderFinalScoreBumper(info.title, info.maker, categoryDisplayName, award, score);
          }
        }

        return {
          type: "image",
          id,
          info,
          score,
          url,
          banner
        };
      });

    const delay = 10000;

    return (
      <>
        <GlobalHotKeys keyMap={this.swiperKeyMap} handlers={this.swiperHandlers}>
          <div className={styles.Swiper} >
            <Swiper
              className={styles.Show}
              ref={this.swiperRef}
              pagination={false}
              navigation={false}
              autoplay={{ delay }}
              modules={[Autoplay]}
              onAfterInit={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
              onSlideChange={(swiper) => {
                this.setState(() => {
                  return {
                    bannerId: lodash.get(data, `[${swiper.activeIndex}].id`),
                  };
                });
              }}
            >
              {
                data.map(({
                  id,
                  type,
                  info,
                  slide,
                  score,
                  url,
                  banner
                }) => {
                  return (
                    <SwiperSlide key={id} className={styles.slide}>
                      <div className={styles.winnerImage}>
                        {this.renderImage(id, type, slide, url)}
                      </div>
                      {banner && this.state.bannerId === id &&
                        <LowerThirdBanner delay='900ms' duration={6000} key={`${this.state.bannerId}-bumper-results-final`}>
                          {banner}
                        </LowerThirdBanner>
                      }
                    </SwiperSlide>
                  );
                })
              }
            </Swiper>
          </div>
        </GlobalHotKeys>
      </>
    );
  }

  clearBanner() {
    this.setState(() => {
      return {
        showBanner: false
      };
    });
  }

  renderNoResultsContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          Congratulations, you've found absolutely nothing!
        </Typography>
      </div>
    );
  }

  renderUnknownContent() {
    return (
      <div className={clsx(styles.helpText, styles.lonely)}>
        <SearchIcon fontSize="large" />
        <Typography variant="h6" color="inherit" component="div">
          We've found intelligent life, but we're not sure what to do with it...
        </Typography>
      </div>
    );
  }


  render() {
    let innards;
    if (this.props.showInterstitial) {
      innards = this.renderInterstitial();
    } else if (this.props.hasCeremony && this.props.eventState === 'ended' && !this.props.initializing) {
      innards = this.renderWinnersSwiper();
    } else if (!this.props.hasCeremony) {
        innards = this.renderNoResultsContent();
    } else {
      innards = this.renderUnknownContent();
    }

    const buttonStyles = {
      position: 'absolute',
      bottom: 0,
      left: '50%',
      transform: 'translateX(-50%)',
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 'auto',
      backgroundColor: 'white',
    };
    return (
      <FullScreen buttonStyles={buttonStyles}>
        <div ref={this.CeremonyRef} className={styles.Ceremony}>
          {innards}
        </div>
      </FullScreen>
    );
  }
}


Ceremony.propTypes = {
  eventType: PropTypes.string,
  eventState: PropTypes.oneOf(['upcoming', 'scoring-test', 'preview-images', 'running', 'scoring-images', 'image-voting', 'elimination', 'pick-winners', 'intermission', 'report-winners', 'choosing-bestInShow', 'ended']),
  showInterstitial: PropTypes.bool.isRequired,
  winners: PropTypes.arrayOf(PropTypes.object),
  getImageInfo: PropTypes.func,
  bestInShow: PropTypes.string,
  hasCeremony: PropTypes.bool,
  hideScores: PropTypes.bool,
  showScoresInExtras: PropTypes.bool,
  standings: PropTypes.object,
  title: PropTypes.string,
};

Ceremony.defaultProps = {
  eventType: 'placed-competition',
  showInterstitial: true,
  eventState: 'upcoming',
  getImageInfo: () => { },
  bestInShow: null,
  hasCeremony: false,
  hideScores: false,
  showScoresInExtras: false,
  standings: {},
  title: "Ceremony to end all ceremonies",
};

Ceremony.connector = (state) => {
  const initializing = !(state.appState.ready && state.appState['images.ready']);

  const { bestInShow, awards, scores } = state.liveState.gamePlay;
  
  const liveEvent = state.appState.config.campaigns[state.liveState.campaign].liveEvent;
  const standings = state.ceremonyExtras.standings;
  const { ceremonyExtras } = liveEvent;
  const placesNames = liveEvent.placesLongnames;
  const placesShortNames = liveEvent.placesShortnames;
  const categoryDisplayNames = state.appState.config.campaigns[state.liveState.campaign].categoryDisplayNames;
  const bestInShowTitle = state.appState.config.campaigns[state.liveState.campaign].specialAwardTitle || 'Best in Show';
  const eventFeatures = liveEvent['event-features'] || [];
  const hasCeremony = eventFeatures.includes('ceremony');
  const hideScores = eventFeatures.includes('hide-scores-in-ceremony');
  const showScoresInExtras = eventFeatures.includes('show-scores-in-ceremony-extras');
  const includeBestInShowAsSpecialAward =
      bestInShow && 
      eventFeatures.includes('showcase-special-award') &&
      eventFeatures.includes('best-in-show-is-special-award') &&
      eventFeatures.includes('special-award');

  const eventType = liveEvent["event-type"];
  const eventState = state.liveState.gamePlay.state;

  const getAwardById = (id) => {
    const award = awards[id];
    const index = placesShortNames.indexOf(award);
    return index >= 0 ? placesNames[index] : 'Honorable Mention';
  };

  const extraImages = ceremonyExtras ? (ceremonyExtras.images || []) : [];
  const extraAwards = ceremonyExtras ? (ceremonyExtras.awards || {}) : {};

  const getImageInfo = (id) => {
    return state.images[id] || state.ceremonyExtras.images.find(extra => extra.id === id);
  };

  const formatAwardById = (id, award) => {
    const image = getImageInfo(id);
    const lastPart = lodash.startCase(image.category);
    return `${award}, ${lastPart}`;
  };

  const getClubNameById = (id)  => {
    const club = state.appState.config.clubs.find(club => club.id === id); 
    return club ? club.name : 'Darkroomers';
  };

  let winners;


  if (!initializing) {
    winners = Object.entries(scores).map(([id, score]) => {
      return { ...state.images[id], score };
    }).reduce((acc, current) => {
      if (eventType === 'placed-competition-with-mentions') {
        acc[current.id] = {
          ...current,
          clubName: getClubNameById(current.club),
          award: getAwardById(current.id),
        };
        return acc;
      }
      if (acc[current.category]) {
        acc[current.category].push(current);
      } else {
        acc[current.category] = [current];
      }
      return acc;
    }, {});

    if (eventType === 'placed-competition') {
      winners = Object.fromEntries(
        Object.entries(winners)
          .map(([category, scoredImages]) => {
            return [category, 
                scoredImages.sort((a, b) => {
                  return (
                    b.score - a.score
                  );
                })
                .slice(0, placesNames.length)
                .map((image, index) => {
                  return {
                    id: image.id,
                    url: image.url,
                    score: image.score,
                    award: placesNames[index],
                    categoryDisplayName: categoryDisplayNames[image.category]
                  };
                })
              ];
          })
      );
      
      winners = Object.values(winners).flat();

      winners = winners.reduce((acc, current) => {
        const last = acc.length > 0 ? acc[acc.length - 1] : {};
        if (last.categoryDisplayName !== current.categoryDisplayName) {
          return [
            ...(acc || []),          
            { 
              slide: { 
                type: "title",
                title: current.categoryDisplayName 
              }
            },
            current];
        }
        return [
          ...acc,
          current
        ];
      }, []);

      const ceremonyExtras = Object.entries(
          Object.fromEntries(
            Object.entries(state.ceremonyExtras.images
            .reduce((acc, current) => {
              const leaf = acc[current.campaign];
              if (leaf && leaf[current.category]) {
                leaf[current.category].push(current);
              } else {
                lodash.set(acc, `${current.campaign}.${current.category}`, [current]);
              }
              return acc;
            }, {})))
          ).map(([campaign, categories]) => {
            return [
              campaign,
              Object.fromEntries(
                Object.entries(categories)
                  .map(([category, images]) => {
                    return [
                      category,
                      images.sort((a, b) => {
                        return (
                          b.totalVotes - a.totalVotes
                        );
                      })
                    ];
                  })
              )
            ];
          })
      .map(([, categories]) => {
        return Object.values(categories).flat();
      })
      .flat()
      .map((extra) => {
        return {
          id: extra.id,
          categoryDisplayName: state.appState.config.allCampaigns[extra.campaign].name,
          theme: state.appState.config.allCampaigns[extra.campaign].theme,
          award: extra.place,
          score: extra.totalVotes,
          url: extra.url
        };
      })
      .reduce((acc, current) => {
        const last = acc.length > 0 ? acc[acc.length - 1] : {};
        if (last.categoryDisplayName !== current.categoryDisplayName) {
          return [
            ...(acc || []),          
            { 
              slide: { 
                type: "title",
                title: current.categoryDisplayName, subtitle: current.theme 
              }
            },
            current];
        }
        return [
          ...acc,
          current
        ];
      }, []);

      winners = [
        ...winners,
        ...ceremonyExtras,
        { 
          slide: { 
            type: "standings",
            title: "Final Results" 
          }
        },
        { 
          slide: { 
            type: "signoff",
          }
        },
      ];
    } else {
      winners = Object
        .values(winners)
        .sort((a, b) => {
          return (
            b.award - a.award
          );
        });
      if (includeBestInShowAsSpecialAward) {
        const current = getImageInfo(bestInShow);
        winners.unshift({
          ...current,
          award: bestInShowTitle,
          clubName: getClubNameById(current.club),
          score: null,
        });
        winners = [
          ...winners,
          ...extraImages.map((id) => {

            const info = extraAwards[id];
           
            return { 
                ...getImageInfo(id),
                clubName: formatAwardById(id, info.award),
                score: info.score,
                award: info.awardSubText,
                isExtra: true
              };
          })
        ];
      }
    }
  }

  const showInterstitial = initializing || eventState !== 'ended';

  return {
    showInterstitial,
    eventState,
    winners: [
      { 
        slide: {
          type: "title" ,
          title: state.appState.config.campaigns[state.liveState.campaign].name,
          subtitle: new Date().toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' }),
         },

       },
      ...(winners || [])
    ],
    getImageInfo,
    eventType,
    bestInShow,
    hideScores,
    showScoresInExtras,
    hasCeremony,
    standings,
    title: state.appState.config.campaigns[state.liveState.campaign].name,
  };
};

Ceremony.commander = (dispatch) => {
  return {
  };
};


export default connect(
  Ceremony.connector,
  Ceremony.commander
)(Ceremony);
