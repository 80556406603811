import { call, put, takeEvery, takeLatest, all, delay, throttle, select } from 'redux-saga/effects';
import HttpStatus from 'http-status-codes';
import lodash from 'lodash';

import * as actions from './actions';

import {
  getCampaigns,
  getCurrentCampaign,
  hasCurrentEventStarted,
  hasCurrentEventEnded,
  isAppReady,
  isOperator,
  isDummyUser,
} from './selectors';

import backend from './backend';

import AppController from '../Controllers/AppController';
import { preloadImage } from '../Utils/imageUtils';

const HEALTH_INTERVAL = 30000; // update every 30 seconds
const STATE_INTERVAL = 3000; // update state every 3 seconds

function* navigate(action) {
  yield AppController.navigate(action.payload.location, action.payload.method || AppController.navigate.replace);
}

function* doLogout() {
  yield AppController.logout();
}

function* doLogin() {
  yield AppController.login();
}

function* synchronizeModelWithRequest(action) {
  try {
    const campaigns = yield select(getCampaigns);
    const currentCampaign = yield select(getCurrentCampaign);
    const ready = yield select(isAppReady);

    if (!ready || !currentCampaign || !campaigns) {
      return;
    }

    const parts = action.payload.location.split('/').filter(part => part.length > 0);

    let tool = parts.shift();


    if (tool === 'standings') {
      const initiativeId = parts.shift();
      if (!initiativeId) {
        yield put({
          type: actions.NAVIGATE,
          payload: {
            location: `/coming`
          }
        });
      } else {
        yield put({
          type: actions.SET_ACTIVE_VIEW,
          payload: {
            view: 'expanded'
          }
        });
        yield put({
          type: actions.FETCH_STANDINGS,
          payload: {
            initiative: initiativeId,
          }
        });
      }
      return;
    }

    let campaignId = parts.shift();
    const camp = campaigns[campaignId];

    let theRest = parts.join('/');
    theRest = theRest ? `/${theRest}` : '';

    if (!campaignId || campaignId === 'current' || !camp) {
      if (!tool) {
        tool = 'live';
      }

      if (!currentCampaign) {
        if (!campaignId && tool === 'coming') {
          return;
        }
        yield put({
          type: actions.NAVIGATE,
          payload: {
            location: `/coming`
          }
        });
      } else {
        campaignId = currentCampaign.id;
        if (tool === 'coming') {
          tool = 'live';
        }

        yield put({
          type: actions.NAVIGATE,
          payload: {
            location: `/${tool}/${campaignId}${theRest}`
          }
        });
      }
      return;
    }

    yield put({
      type: actions.SET_ACTIVE_CAMPAIGN,
      payload: {
        campaign: camp.id
      }
    });

    if (tool === 'ceremony') {
      yield put({
        type: actions.FETCH_IMAGE_LIST,
        payload: {
          forCeremony: true,
          campaign: camp.id
        }
      });
    } else if (tool === 'review') {
      yield put({
        type: actions.FETCH_IMAGE_LIST,
        payload: {
          forReview: true,
          campaign: camp.id
        }
      });
    } else {
    yield put({
      type: actions.FETCH_IMAGE_LIST,
      payload: {
        campaign: camp.id
      }
    });
  }
    yield put({ type: actions.INIT_STATE_SYNC });
  } catch (error) {
    AppController.reportError('synchronizing datastore', error);
  }
}

function* fetchImages(action) {
  
  try {
    const campaign = yield select(getCurrentCampaign);
    const campaigns = yield select(getCampaigns);
    let ceremonyStandings;
    if (campaign && campaign.id) {
      AppController.enterPotentiallyLengthyOperation();
      let images;
      let additionalImages = [];
      let ceremonyExtras = [];

      if (action.payload.forCeremony) {
        const extraCampaigns = lodash.get(campaign.liveEvent, "ceremonyExtras.campaigns", campaign.liveEvent.ceremonyExtras);
        if (Array.isArray(extraCampaigns)) {
          ceremonyExtras = yield call(backend.fetchCeremonyExtras, campaign.id);
        }
        const ceremonyYear = lodash.get(campaign.liveEvent, "ceremonyStandings.year");
        if (ceremonyYear) { 
          ceremonyStandings = yield call(backend.fetchStandings, ceremonyYear);
        }
      }
      if (action.payload.forReview) {
        images = yield call(backend.fetchImagesForReview, campaign.id);
      } else {
        images = yield call(backend.fetchImages, campaign.id);
        const makeupCampaign = campaign.liveEvent && campaign.liveEvent["makeup-to-campaign"];

        if (makeupCampaign) {
          const campaignImages = yield call(backend.fetchImages, makeupCampaign);
          additionalImages = [
            ...additionalImages,
            ...campaignImages
          ];
        }

        if (campaign.basis) {
          const relatedCampaigns = Object.values(campaigns).filter(camp => camp.initiativeId === campaign.basis);
          for (const relatedCampaign of relatedCampaigns) {
            const campaignImages = yield call(backend.fetchImages, relatedCampaign.id);
            additionalImages = [
              ...additionalImages,
              ...campaignImages
            ];
          }
        }
      }

      yield put({
        type: actions.INIT_IMAGE_LIST,
        payload: {
          images: [
            ...images,
            ...additionalImages
          ]
        }
      });
      if (Array.isArray(ceremonyExtras)) {
        yield put({
          type: actions.INIT_CEREMONY_EXTRAS_LIST,
          payload: {
            images: ceremonyExtras
          }
        });
      }
      if (ceremonyStandings) {
        yield put({
          type: actions.INIT_CEREMONY_STANDINGS,
          payload: {
            standings: ceremonyStandings
          }
        });
      }
      yield put({
        type: actions.UPDATE_IMAGES_READY_STATE,
        payload: {
          "images.ready": true
        }
      });
      images.map(image => preloadImage(image.url));
    }
  } catch (error) {
    AppController.reportError('fetching images', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* doCommand(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    if (campaign && campaign.id) {
      const state = yield call(backend.invokeCommand, campaign.id, action.payload.command, action.payload.params, action.payload.method);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportFormattedError(`Command Invocation ${action.payload.command}`, error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* revealSecrets(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const secrets = yield call(backend.fetchSecrets, campaign.id);
    AppController.revealSecrets(secrets);
  } catch (error) {
    AppController.reportError('scoring image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* voteImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(
        backend.voteImage,
        campaign.id,
        action.payload.image,
        action.payload.vote,
        action.payload.abstain,
        action.payload.override
      );
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('voting image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* scoreImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(
        backend.scoreImage,
        campaign.id,
        action.payload.image,
        action.payload.score,
        action.payload.abstain,
        action.payload.override
      );
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('scoring image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* eliminateImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(backend.eliminateImage, campaign.id, action.payload.image);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('eliminating image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* awardImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(backend.awardImage, campaign.id, action.payload.image);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('awarding image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* spotlightImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(backend.spotlightImage, campaign.id, action.payload.image);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('spotlighting image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* removeSpotlight() {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(backend.removeSpotlight, campaign.id);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('spotlighting image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* disqualifyImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (!hasStarted) {
      const state = yield call(backend.disqualifyImage, campaign.id, action.payload.image);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('presenting image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }

}

function* presentImage(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();
    const campaign = yield select(getCurrentCampaign);
    const hasStarted = yield select(hasCurrentEventStarted);
    if (hasStarted) {
      const state = yield call(backend.presentImage, campaign.id, action.payload.image, action.payload.rescore);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });
    }
  } catch (error) {
    AppController.reportError('presenting image', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}

function* fetchHealth() {

  try {
    const health = yield call(backend.fetchHealth);
    yield put({
      type: actions.UPDATE_HEALTH,
      payload: {
        ...health,
        connectionStatus: 'connected',
      }
    });
    const user = yield call(backend.fetchUserInfo);
    yield put({
      type: actions.UPDATE_USER,
      payload: {
        user,
      }
    });
  } catch (error) {
    if (error.statusCode === HttpStatus.UNAUTHORIZED) {
      if (AppController.restrictedAccess) {
        yield AppController.login();
      }
    } else {
      yield put({
        type: actions.UPDATE_HEALTH,
        payload: {
          status: 'down',
          connectionStatus: 'disconnected',
        }
      });
    }
  }
}

function* fetchState() {
  try {
    const campaign = yield select(getCurrentCampaign);
    if (campaign && campaign.id) {
      const state = yield call(backend.fetchState, campaign.id);
      yield put({
        type: actions.UPDATE_STATE,
        payload: {
          campaign: campaign.id,
          ...campaign.liveEvent,
          ...state,
        }
      });

      const currentEventEnded = yield select(hasCurrentEventEnded);
      const operator = yield select(isOperator);
      const dummy = yield select(isDummyUser);
      const tool = AppController.currentTool;
      const isGallery = ['gallery', 'directory'].includes(tool);

      if (!dummy && !operator && currentEventEnded && !isGallery) {
        yield put({
          type: actions.NAVIGATE,
          payload: {
            location: '/directory',
            type: AppController.replace
          },
        });
      }
    }
  } catch (error) {
    console.log('cannot fetch status from server');
  }
}

function* fetchStandings(action) {
  try {
    AppController.enterPotentiallyLengthyOperation();

    const standings = yield call(backend.fetchStandings, action.payload.initiative);
    yield put({
      type: actions.INIT_STANDINGS,
      payload: {
        ...standings
      }
    });
  } catch (error) {
    AppController.reportError('fetching standings', error);
  } finally {
    AppController.exitPotentiallyLengthyOperation();
  }
}


function* initServerHealth(action) {
  const interval = (action && action.interval) || HEALTH_INTERVAL;

  while (true) {
    yield put({
      type: actions.FETCH_HEALTH
    });
    yield delay(interval);
  }
}

function* initStateSync(action) {
  const interval = (action && action.interval) || STATE_INTERVAL;

  while (true) {
    yield put({
      type: actions.FETCH_STATE
    });
    yield delay(interval);
  }
}

function* initialize(action) {
  try {
    const config = yield call(backend.fetchConfiguration);
    yield put({
      type: actions.SERVER_CONFIG,
      payload: {
        config,
      },
    });
    yield put({ type: actions.INIT_SERVER_HEALTH });
    if (action.payload && action.payload.location) {
      yield put({
        type: actions.NAVIGATE,
        payload: action.payload,
      });
    }
    yield put({
      type: actions.UPDATE_READY_STATE
    });
    yield AppController.initialized();
  } catch (error) {
    AppController.reportError('initializing datastore', error);
  }
}

function* throttleCommand(action) {
  yield put({
    type: actions.THROTTLE_UI_STATE,
    payload: {
      throttled: true
    },
  });

  yield put(action.payload);
  yield delay(2000);

  yield put({
    type: actions.THROTTLE_UI_STATE,
    payload: {
      throttled: false
    },
  });
}

function* watchRevealSecrets() {
  yield takeEvery(actions.REVEAL_SECRETS, revealSecrets);
}

function* watchFetchHealth() {
  yield throttle(HEALTH_INTERVAL, actions.FETCH_HEALTH, fetchHealth);
}

function* watchFetchState() {
  yield throttle(STATE_INTERVAL, actions.FETCH_STATE, fetchState);
}

function* watchInitStateSync() {
  yield takeEvery(actions.INIT_STATE_SYNC, initStateSync);
}

function* watchInitServerHealth() {
  yield takeEvery(actions.INIT_SERVER_HEALTH, initServerHealth);
}

function* watchFetchImages() {
  yield takeEvery(actions.FETCH_IMAGE_LIST, fetchImages);
}

function* watchInitialize() {
  yield takeEvery(actions.INITIALIZE, initialize);
}

function* watchNavigate() {
  yield takeLatest(actions.NAVIGATE, navigate);
}

function* watchScoreImage() {
  yield takeEvery(actions.SCORE_IMAGE, scoreImage);
}

function* watchVoteImage() {
  yield takeEvery(actions.VOTE_IMAGE, voteImage);
}

function* watchEliminateImage() {
  yield takeEvery(actions.ELIMINATE_IMAGE, eliminateImage);
}

function* watchAwardImage() {
  yield takeEvery(actions.AWARD_IMAGE, awardImage);
}

function* watchSpotlightImage() {
  yield takeEvery(actions.SPOTLIGHT_IMAGE, spotlightImage);
}

function* watchRemoveSpotlight() {
  yield takeEvery(actions.REMOVE_SPOTLIGHT, removeSpotlight);
}

function* watchSynchronize() {
  yield takeEvery(actions.SYNCHRONIZE_DATA_MODEL_WITH_REQUEST, synchronizeModelWithRequest);
}

function* watchLiveCommand() {
  yield takeEvery(actions.LIVE_COMMAND, doCommand);
}

function* watchLogout() {
  yield takeLatest(actions.LOGOUT, doLogout);
}

function* watchLogin() {
  yield takeLatest(actions.LOGIN, doLogin);
}

function* watchPresentImage() {
  yield takeLatest(actions.PRESENT_IMAGE, presentImage);
}

function* watchDisqualifyImage() {
  yield takeEvery(actions.DISQUALIFY_IMAGE, disqualifyImage);
}

function* watchThrottleUI() {
  yield takeLatest(actions.THROTTLE_COMMAND, throttleCommand);
}

function* watchFetchStandings() {
  yield takeLatest(actions.FETCH_STANDINGS, fetchStandings);
}


export default function* rootSaga() {
  yield all([
    watchFetchImages(),
    watchFetchHealth(),
    watchInitialize(),
    watchInitServerHealth(),
    watchNavigate(),
    watchSynchronize(),
    watchInitStateSync(),
    watchScoreImage(),
    watchVoteImage(),
    watchPresentImage(),
    watchEliminateImage(),
    watchAwardImage(),
    watchSpotlightImage(),
    watchRemoveSpotlight(),
    watchSynchronize(),
    watchFetchState(),
    watchLiveCommand(),
    watchRevealSecrets(),
    watchDisqualifyImage(),
    watchLogout(),
    watchLogin(),
    watchThrottleUI(),
    watchFetchStandings(),
  ]);
}
