export const SET_ACTIVE_CAMPAIGN = 'cyclone/application/filters/setActiveCampaign';
export const SET_ACTIVE_VIEW = 'cyclone/application/view/set';

export const UPDATE_HEALTH = 'cyclone/application/health/update';
export const UPDATE_STATE = 'cyclone/application/state/update';
export const SERVER_CONFIG = 'cyclone/application/config';
export const UPDATE_READY_STATE = 'cyclone/application/ready-state/update';
export const UPDATE_IMAGES_READY_STATE = 'cyclone/application/image-state/update';
export const UPDATE_USER = 'thunder/application/user';

export const INIT_IMAGE_LIST = 'cyclone/images/initialize';
export const FETCH_IMAGE_LIST = 'cyclone-server/images/fetch';
export const INIT_CEREMONY_EXTRAS_LIST = `cyclone/ceremony/extras/initialize`;
export const INIT_CEREMONY_STANDINGS = `cyclone/ceremony/standings/initialize`;

export const SCORE_IMAGE = 'cyclone-server/image/score';
export const VOTE_IMAGE = 'cyclone-server/image/vote';
export const ELIMINATE_IMAGE = 'cyclone-server/image/eliminate';
export const AWARD_IMAGE = 'cyclone-server/image/award';
export const SPOTLIGHT_IMAGE = 'cyclone-server/image/spotlight';
export const REMOVE_SPOTLIGHT = 'cyclone-server/image/remove-spotlight';
export const PRESENT_IMAGE = 'cyclone-server/image/present';
export const DISQUALIFY_IMAGE = 'cyclone-server/image/dq';

export const INIT_SERVER_HEALTH = 'cyclone-server/health/initialize';
export const FETCH_HEALTH = 'cyclone-server/health/fetch';

export const FETCH_STANDINGS = 'cyclone-server/standings/fetch';
export const INIT_STANDINGS = 'cyclone/standings/initialize';

export const INIT_STATE_SYNC = 'cyclone-server/state-sync/initialize';
export const FETCH_STATE = 'cyclone-server/state/fetch';

export const NAVIGATE = 'cyclone/navigate';
export const INITIALIZE = 'cyclone/initialize';
export const SYNCHRONIZE_DATA_MODEL_WITH_REQUEST = 'cyclone/synchronize';

// FIXME: either remove or add schemas
export const INIT_SCHEMA_LIST = 'cyclone/schemas/initialize';
export const FETCH_SCHEMA_LIST = 'cyclone-server/schemas/initialize';

export const LIVE_COMMAND = 'cyclone-server/live/command';
export const REVEAL_SECRETS = 'cyclone-server/live/reveal/secrets';

export const LOGOUT = 'cyclone/logout';
export const LOGIN = 'cyclone/login';

// ALLOW the UI a chance to breathe...
export const THROTTLE_COMMAND = 'cyclone/ui/throttle';
export const THROTTLE_UI_STATE = 'cyclone/state/throttle';
