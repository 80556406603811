import * as actions from '../actions';
import lodash from 'lodash';

const DEFAULT_STATE = {
  images: [],
  standings: {},
};

function initializeCeremonyExtrasList(state, images) {
  return {
    ...state,
    images: images.map(image => {
      if (image.maker) {
        return image;
      }
      return {
          ...image,
          maker: image.user.name
      };
    })
  };
}

function initializeCeremonyStandings(state, standings) {
  let newState = { ...state, standings: {} };

  standings.forEach(standing => {
    standing.leaderBoard.forEach(section => {
      section.leaderBoard.forEach(leader => {
        const current = lodash.get(newState, `standings.${leader.userName}.${section.category}`, 0);
        lodash.set(newState, `standings.${leader.userName}.name`, leader.name);
        lodash.set(newState, `standings.${leader.userName}.${section.category}`, leader.score + current);
      });
    });
  });

  return newState;
}


export default function ceremonyExtras(state = DEFAULT_STATE, action) {
  switch (action.type) {

    case actions.INIT_CEREMONY_EXTRAS_LIST:
      return initializeCeremonyExtrasList(state, action.payload.images);

    case actions.INIT_CEREMONY_STANDINGS:
      return initializeCeremonyStandings(state, action.payload.standings);

    default:
      break;                          
  }

  return state;
}
